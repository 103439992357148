export enum Feature {
	ChargebeeCheckout = 'chargebee_checkout',
	DocumentView = 'document_view',
	PropertyView = 'property_view',
	DueDateEditing = 'due_date_editing',
	ModifySubscription = 'modify_subscription',
	ModifyPaymentSource = 'modify_payment_source',
	InternalNote = 'internal_note',
	CreateOrder = 'create_order',
	InsuranceObject = 'insurance_object',
	AccountCreationOrder = 'account_creation_order',
	OfferView = 'offer_view',
	AppointmentView = 'appointment_view',
	DashBoard = 'relay_dashboard',
	UseTechemCountryConfigs = 'use_techem_country_configs',
	ShowNewDesignToggleBanner = 'show_new_design_toggle_banner',
	PricingV2RolledOut = 'pricing_v2_rolled_out'
}

export enum AccountSpecificFeature {
	DesignSystem = 'design_system',
	AutoAcceptCustomersFeature = 'has_auto_accept_customers_feature',
	AutoAcceptPropertiesFeature = 'has_auto_accept_properties_feature',
	PricingModelV2 = 'pricing_model_v2',
	TicketCreation = 'ticket_creation',
	ShowPricingV2InfoBanner = 'show_pricing_v2_info_banner',
	CouponPromo = 'coupon_promo',
	PantaeniusApiV2 = 'pantaenius_api_v2'
}
